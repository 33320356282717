import { useEffect, useRef, useState } from "react"
import useClickOutside from "../../hooks/useClickOutside"
import SelectArrows from "../vectors/SelectArrows";

export default function Select({ onSelection, defaultSelection, options, renderOption, className, optionValueKey = 'value', optionTextKey = 'label', id = 'default', disabled = false }) {
    const [isDrowndownShown, setIsDropdownShown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(defaultSelection || options[0] || { [optionTextKey]: '', [optionValueKey]: '' })
    const dropdownRef = useRef(null)

    useClickOutside(dropdownRef, () => setIsDropdownShown(false))

    useEffect(() => {
        if (defaultSelection && JSON.stringify(defaultSelection) !== JSON.stringify(selectedOption)) {
            setSelectedOption(defaultSelection)
        }
    }, [defaultSelection]);

    const handleSelectClick = () => {
        if (disabled) {
            return
        }

        setIsDropdownShown(!isDrowndownShown)
    }

    const handleOptionClick = (option) => {
        if (disabled) {
            return
        }

        setSelectedOption(() => {
            onSelection(option)
            setIsDropdownShown(false)
            return option
        })
    }

    const handleOptionHover = (event) => {
        const { currentTarget } = event
        currentTarget.classList.toggle('bg-secondary-blue')
        currentTarget.classList.toggle('[&>*]:!text-white')
    }

    return (
        <div className="w-full" data-test={`select-box-container-${id}`} data-testid={`${id}-wrapper`} ref={dropdownRef}>
            <div className={`relative ${className || ''}`}>
                <button data-testid={`${id}-button`} onClick={handleSelectClick} type="button" className={`h-full relative w-full ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-full border-0 bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none ${!disabled ? 'focus:ring-secondary-blue focus:ring-2 hover:ring-secondary-blue' : ''} sm:text-sm sm:leading-6`} aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                    <span className="flex items-center">
                        <span data-testid={`${id}-text-display`} className={`ml-1 block truncate text-base ${disabled ? 'text-gray-400' : 'text-primary-blue'}`}>{selectedOption[optionTextKey]}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <SelectArrows />
                    </span>
                </button>

                {isDrowndownShown && 
                    <ul data-testid={`${id}-ul`} className="absolute z-10 mt-1 pl-0 max-h-56 w-full overflow-auto rounded-2xl bg-white py-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                        {options.map((option) => {
                            const isSelected = option[optionValueKey] === selectedOption[optionValueKey]

                            return (
                                <li data-testid={`${id}-li:${option[optionValueKey]}-${option[optionTextKey]}`} key={`select-options:${option[optionValueKey]}-${option[optionTextKey]}`} onClick={() => handleOptionClick(option)} onMouseEnter={handleOptionHover} onMouseLeave={handleOptionHover} className="relative cursor-pointer select-none py-2 pr-3 pl-3 text-gray-900 list-none" id="listbox-option-0" role="option">
                                    {renderOption(option, isSelected)}
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
}